.acf-ui-datepicker {
    .ui-widget-content {
        border-color: $color-light !important;
    }

    .ui-datepicker.ui-widget-content {
        width: 370px;
        z-index: 1 !important;
        color: $color-text;
        background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--neutral repeat;
        border-color: $color-light !important;
        border-radius: 4px;
        box-shadow: $shadow;

        .ui-icon {
            filter: contrast(0.5);
        }

        .ui-datepicker-title {
            display: flex;
            justify-content: center;

            .ui-datepicker-month {
                margin-right: 10px;
            }

            .ui-datepicker-month,
            .ui-datepicker-year {
                width: 80px;
                color: $color-text;

                option {
                    color: $color-text;
                }
            }
        }

        .ui-widget-header {
            background: transparent;
            border: none;

            .ui-state-hover {
                border: 1px solid $color-main;
                background: $color-main;
                color: $color-white;
            }
        }

        td {
            a {
                padding: .5em;

                &.ui-state-default {
                    border: 1px solid $color-white;
                    background: $color-white;
                    color: $color-text;
                }

                &.ui-state-highlight {
                    color: $color-white;
                    background: $color-dark;
                    border-color: $color-dark;
                }

                &.ui-state-hover,
                &.ui-state-active { 
                    color: $color-text;
                    background: $color-main;
                    border-color: $color-main;

                    &.ui-state-highlight {
                        color: $color-white;
                        background: $color-dark !important;
                        border-color: $color-dark !important;
                    }
                }

            }
        }

        button {
            border: $btn-border-width $btn-border-style $btn-border-color;
            border-radius: 0;
            color: $btn-color;
            background: $btn-bg;
            padding: 10px 25px;
            margin: 10px 0;
            font-weight: $btn-font-weight;
            opacity: 1;

            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked,
            &.ui-state-hover{
                color: $btn-color--hover;
                background: $btn-bg--hover;
                border: $btn-border-width $btn-border-style $btn-border-color--hover;
            }
        }
    }

    .ui-timepicker-div {
        dl {
            dd.ui_tpicker_second {
                display: none;
            }
        }
    }

}

.ui-datepicker-calendar tr:last-child .ui-state-active {
    height: 37px !important;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .acf-ui-datepicker {
        .ui-datepicker.ui-widget-content {
            width: 300px;
        }
    }
    
    .ui-datepicker.ui-widget-content {
        width: 300px;
    }
}


// 640
@media screen and (max-width: $small) {    
    .acf-ui-datepicker .ui-datepicker.ui-widget-content {
        z-index: 2 !important;
    }
}
