body.home .footer {
    margin-top: -50px;

    &__top {
        padding: 120px 0 80px;
    }
}

.footer {
    display: flex;
    flex-direction: column;

    &__top {
        width: 100%;
        background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--neutral repeat;
        padding: 50px 0;
    }

    &__container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        column-gap: 130px;
    }

    &__logo-contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 30px;
    }

    &__logo {
        width: 270px;
        height: 100px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__contact {
        width: 270px;
        padding: 0 15px;
    }

    &__content {
        display: flex;
        column-gap: 70px;
        margin-top: 7.5px;
    }

    &__mairie,
    &__horaires {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__mairie {
        width: 330px;
    }

    &__horaires {
        width: 300px;
        margin-top: 7.5px;
    }

    &__title {
        font-size: $font-size--4;
        line-height: 1.10em;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin: 0;

        span {
            font-size: $font-size--text;
            font-weight: $font-weight-light;
        }
    }

    &__text {
        color: $color-text;
        margin-top: 5px;
    }

    &__tel {
        display: flex;
        align-items: center;
        column-gap: 7.5px;
        font-size: $font-size--6;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin-top: 30px;

        &:hover,
        &:focus {
            svg {
                fill: $color-text;
                background-color: $color-main;
            }
        }

        svg {
            width: 26px;
            height: 26px;
            fill: $color-white;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: $duration;
        }
    }

    &-socials {
        display: flex;
        column-gap: 10px;
        margin-top: 20px;

        &__item {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 30px;
                height: 30px;
                fill: $color-white;
                transition: $duration;
            }
        }
    }

    &-bottom {
        display: flex;
        background-color: $color-bg--body;
        padding: 38.5px 0;

        &__container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    &-menu {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 60px;

        &__item {
            position: relative;
        }

        &__link {
            font-size: $font-size--text-small;
            color: $color-text;
            text-transform: uppercase;
            padding: 5px 0;
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-second;
            }
        }
    }
}





// 1200
@media screen and (max-width: $large) {
    body.home .footer__top {
        padding: 127.5px 0 80px;
    }

    .footer {        
        &__container {
            column-gap: 70px;
        }
    
        &__logo-contact {
            row-gap: 37.5px;
        }
    
        &__logo {
            width: 230px;
            height: 86px;
        }
    
        &__contact {
            width: 230px;
        }
    
        &__content {
            flex: 1;
            justify-content: space-between;
            column-gap: 0;
            margin-top: 0;
        }
    
        &__mairie,
        &__horaires {
            width: 300px;
        }

        &__horaires {
            margin-top: 17.5px;
        }
    
        &__text {
            margin-top: 0;
        }
    
        &__tel {
            margin-top: 15px;
        }
    
        &-menu {
            justify-content: space-between;
            column-gap: 0;
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    body.home .footer {
        margin-top: -95px;

        &__top {
            padding: 165px 0 90px;
        }
    }

    .footer { 
        &__container {
            flex-direction: column;
            row-gap: 45px;
        }
    
        &__logo-contact {
            row-gap: 25px;
        }
    
        &__content {
            width: 100%;
        }

        &__horaires {
            margin-top: 12.5px;
        }
    
        &__tel {
            margin-top: 12.5px;
        }

        &-bottom {
            padding: 40px 0;
        }
    
        &-menu {
            justify-content: flex-start;
            column-gap: 35px;
            row-gap: 30px;
        }
    }
}



// 640
@media screen and (max-width: $small) {
    body.home .footer {
        margin-top: -170px;

        &__top {
            padding: 240px 0 70px;
        }
    }

    .footer { 
        &__top {
            padding: 30px 0;
        }
    
        &__content {
            flex-direction: column;
        }

        &__horaires {
            margin-top: 45px;
        }

        &-bottom {
            padding: 35px 0;
        }
    
        &-menu {
            row-gap: 17.5px;
        }
    }
}