.nav {
    &-burger {
        display: none;
    
        &__button {
            display: none;
        }
    
        &__icon {
            width: 100px;
            height: 40px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 7.5px;
            font-size: $font-size--text-small; 
            color: $color-text;
            text-transform: uppercase;
            background-color: $color-main;
            border-radius: 20px;
            margin: 0;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-second;
                cursor: pointer;
            }
    
            .navicon {
                width: 15px;
                height: 2px;
                position: relative;
                top: -1px;
                display: block;
                background: $color-text;
                transition: $duration;
    
                &:before, 
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: block;
                    background: $color-text;
                    transition: $duration;
                }
    
                &:before {
                    top: 5px;
                }
    
                &:after {
                    top: -5px;
                } 
            }
        }
    }



    &-content {
        &__close {
            width: 40px;
            height: 40px;
            display: none;
            justify-content: center;
            align-items: center;
            background-color: $color-second;
            border-color: $color-second;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0 0 0 auto;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: transparent;
                border-color: $color-second;
    
                svg {
                    fill: $color-text;
                }
            }
    
            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: $duration;
            }
        }
    }



    &-menu {
        display: flex;
        column-gap: 60px;
        transition: $duration;

        &__item {
            &:nth-last-child(2) {
                margin-right: -22.5px;
            }

            &:last-child {
                width: 130px;

                .nav-menu__link {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 155px;
                        position: absolute;
                        left: 0;
                        bottom: 20px;
                        z-index: -1;
                        background-color: $color-main;
                        border-radius: 0 0 20px 20px;
                        transition: $duration;
                    }

                    &:hover,
                    &:focus {
                        &::after {
                            height: 175px;
                            bottom: 0;
                            border-radius: 0;
                        }
                    }
                }
            }

            &:hover,
            &:focus-within {
                .nav-menu__link svg {
                    fill: $color-white;
                    background-color: $color-second;
                    margin-top: 5px;
                }

                .nav-submenu {
                    display: flex;
                }
            }
        }

        &__link {
            width: 100%;
            height: 92.5px;
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            font-size: 16.5px;
            line-height: 1.2em;
            color: $color-text;
            text-align: center;
            text-transform: uppercase;
            padding-top: 10px;
            transition: $duration;

            svg {
                width: 20px;
                height: 20px;
                fill: $color-second;
                border-radius: $border-radius--round;
                transition: $duration;
            }
        }
    }



    &-submenu {
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 1;
        display: none;
        background-color: rgba(29, 29, 27, .95);
        padding: 30px 15px;

        &__buttons {
            display: none;
            justify-content: space-between;
            padding: 0 30px;
        }

        &__back,
        &__close {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-second;
            border-color: $color-second;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: transparent;
                border-color: $color-second;
    
                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: $duration;
            }
        }

        &__list {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            width: 100%;
            display: inline-flex;
            margin: -1px; // Fix space beetween inline-flex

            &--child {
                .nav-submenu__link {
                    display: flex;
                    border-bottom: 0;
                    padding: 17px 0 17px 30px;
    
                    &:hover,
                    &:focus {
                        padding-left: 37.5px;
                    }
    
                    &::before {
                        display: none;
                    }
    
                    &::after {
                        right: unset;
                        left: 5px;
                    }
                } 
            }
        }

        &__link {
            width: 100%;
            position: relative;
            display: flex;
            font-weight: $font-weight-bold;
            color: $color-white;
            padding: 20px 30px 20px 0;
            margin-bottom: 1px; // Fix space beetween inline-flex
            border-bottom: 1px solid $color-gray;

            &:hover,
            &:focus {
                color: $color-main;
                padding-left: 15px;
                
                &::before {
                    width: 100%;
                }

                &::after {
                    color: $color-main;
                }
            }

            &::before {
                content: "";
                width: 0;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: -1px;
                background: $color-main;
                transition: $duration;
            }

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-gray;
                transition: all $duration;
            }
        }
    }
}





//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .nav-menu {
            column-gap: 47.5px;
    
            &__item {        
                &:last-child {
                    width: 100px;
    
                    .nav-menu__link {
                        &::after {
                            height: 145px;
                            bottom: 30px;
                        }
                    }
                }
            }
    
            &__link {
                height: 100px;
                font-size: $font-size--text-small;
            }
        }
    }


    
    // 960
    @media screen and (max-width: $medium) {
        .nav {
            &-main--active {
                .nav-content {
                    left: 0;
                }       
            }

            &-burger {
                display: flex;
            }

            &-content {
                width: 100%;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 100%;
                z-index: 990;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--neutral repeat;
                padding: 30px;
                transition: $duration;

                &__close {
                    display: flex;
                }
            }

            &-menu {
                flex-direction: column;

                &__item {
                    width: 100% !important; 

                    &:last-child .nav-menu__link {
                        &::after {
                            display: none;
                        }

                        svg {
                            fill: $color-text;
                            background-color: $color-main;
                        }
                    }

                    &:hover,
                    &:focus-within {
                        &:last-child .nav-menu__link svg {
                            fill: $color-white;
                            background-color: $color-second;
                        }

                        .nav-menu__link {
                            color: $color-text;
                            padding-left: 20px;

                            svg {
                                margin-top: 0;
                            }
                        }
                    }
                    
                    &--active {            
                        .nav-submenu {
                            left: 0;
                        }
                    }
                }

                &__link {
                    height: auto;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: $font-size--text;
                    color: $color-text;
                    padding: 20px 0;

                    br {
                        display: none;
                    }

                    svg {
                        transform: rotate(-90deg);
                    }
                }
            } 

            &-submenu {
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 100%;
                z-index: 2;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--neutral repeat;
                padding: 30px 0;
                transition: $duration;

                &__buttons {
                    display: flex;
                }

                &__list {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 30px; 
                    overflow-y: auto;
                }

                &__link {
                    color: $color-text;
                    border-bottom-color: $color-text;

                    &::before {
                        background-color: $color-second;
                    }

                    &::after {
                        color: $color-text;
                    }

                    &:hover,
                    &:focus {
                        color: $color-second;

                        &::after {
                            color: $color-second;
                        }
                    }
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .nav {
            &-burger {
                &__icon {
                    width: 35px;
                    height: 35px;
                    border-radius: $border-radius--round;

                    &:hover,
                    &:focus {
                        .navicon {
                            background-color: $color-white;

                            &::before,
                            &::after {
                                background-color: $color-white;
                            }
                        }
                    }
                }

                &__text {
                    display: none;
                }
            }
        }
    }
