//======================================================================================================
// Global
//======================================================================================================
body {
    margin-top: 175px;
    transition: $duration;

    &.sticky {
        margin-top: 90px;
    }
}


.front-page,
.footer {
    * {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: 0;
        margin: 0;

        &::before {
            display: none;
        }
    }
}

.home {
    &__title {
        position: relative;
        font-family: $font-family--heading;
        font-size: 45px;
        line-height: .8em;
        font-weight: $font-weight-normal;
        color: $color-second;
        padding-left: 22.5px !important;

        &::before {
            content: url("data:image/svg+xml,%3Csvg width='14' height='14' aria-hidden='true' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.22616 7.71501C-1.03448 8.12868 0.244823 12.0339 1.75173 13.0866C3.37464 14.2197 5.14468 14.1971 6.9366 13.5708C8.22449 13.1212 9.31429 12.3622 10.3335 11.5084C11.7128 10.3525 12.8981 9.03183 13.9061 7.56379C14.0056 7.41919 14.0418 7.30487 13.9359 7.1339C12.5415 4.87803 10.8583 2.86466 8.60541 1.33286C7.34297 0.474174 5.95844 -0.0309648 4.39063 0.00147309C2.61008 0.0396425 0.865261 1.18624 0.264326 2.78293C0.0953548 3.23182 0.0330486 3.65903 0.0581225 4.06794C0.0958284 4.68285 0.331135 5.25637 0.69846 5.79992C0.692218 5.18168 0.840654 4.65799 1.0856 4.24173C1.64861 3.28495 2.72148 2.89578 3.59782 3.23076C4.57404 3.60321 4.91577 4.89015 4.65481 5.50198C4.04071 6.93761 2.726 7.44008 1.22616 7.71501ZM3.95428 2.29645C4.77527 2.60968 5.27423 3.28317 5.52093 3.91076C5.75541 4.50723 5.8415 5.26862 5.57463 5.89431L5.57422 5.89527C4.75011 7.82187 2.9749 8.41112 1.40646 8.69862C1.19029 8.73817 1.12356 8.81976 1.0725 8.94879C0.995426 9.14354 0.964166 9.4894 1.05771 9.97021C1.14801 10.4344 1.33756 10.9322 1.58492 11.3664C1.83846 11.8116 2.11121 12.1178 2.3242 12.2667M3.95428 2.29645C3.14727 1.98817 2.30891 2.0657 1.59181 2.43324C2.21407 1.60964 3.29367 1.02535 4.41168 1.00125C5.73348 0.973985 6.91774 1.39434 8.043 2.15972C10.0321 3.51215 11.5656 5.27921 12.8642 7.30814C11.954 8.58019 10.9008 9.7283 9.69133 10.7419C8.71257 11.5617 7.7306 12.2344 6.607 12.6266C5.01224 13.1841 3.60327 13.1596 2.3242 12.2667' fill='%23F0532B'/%3E%3C/svg%3E");
            position: absolute;
            top: -5px;
            left: 0;
            z-index: 1;
            display: block !important;
        }
    }

    &-button {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 7.5px;
        font-size: $font-size--text-small;
        line-height: 1.15em;
        font-weight: $font-weight-bold;
        font-style: italic;
        color: $color-text;
        text-transform: uppercase;
        text-align: center;
        background-color: $color-main;
        border: 2px solid $color-main;
        border-radius: 2px 2px 20px 2px;
        padding: 0 30px 0 25px;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: transparent;
        }

        svg {
            width: 26px;
            height: 26px;
            fill: $color-text;
        }
    }
}





//======================================================================================================
// ALU
//======================================================================================================
.alu {
    width: 100%;
    position: relative;

    &--video {
        img {
            display: none;
        }
    }

    .swiper-container {
        overflow: visible;
    }

    &__item {
        width: 100%;
        height: 575px !important;
        display: flex;
    }

    &__media {
        width: 100%;
        height: 100%;
        background-color: $color-bg--image;
        overflow: hidden;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &-content {
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 145px;
        transform: translateX(-50%);
        z-index: 2;

        &__container {
            max-width: 570px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 40px 2px;
            padding: 45px 45px 32.5px;
            transition: $duration;

            &[href] {
                &:hover,
                &:focus {
                    background-color: $color-white;

                    .alu__more {
                        margin-left: 15px;

                        svg {
                            fill: $color-text;
                            background-color: $color-main;
                        }
                    }
                }
            }
        }
    }

    &__alu {
        position: relative;
        font-family: $font-family--heading;
        font-size: 35px;
        line-height: 1em;
        color: $color-second;
        padding-left: 22.5px;
        margin-bottom: 7.5px;

        &::before {
            content: url("data:image/svg+xml,%3Csvg width='14' height='14' aria-hidden='true' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.22616 7.71501C-1.03448 8.12868 0.244823 12.0339 1.75173 13.0866C3.37464 14.2197 5.14468 14.1971 6.9366 13.5708C8.22449 13.1212 9.31429 12.3622 10.3335 11.5084C11.7128 10.3525 12.8981 9.03183 13.9061 7.56379C14.0056 7.41919 14.0418 7.30487 13.9359 7.1339C12.5415 4.87803 10.8583 2.86466 8.60541 1.33286C7.34297 0.474174 5.95844 -0.0309648 4.39063 0.00147309C2.61008 0.0396425 0.865261 1.18624 0.264326 2.78293C0.0953548 3.23182 0.0330486 3.65903 0.0581225 4.06794C0.0958284 4.68285 0.331135 5.25637 0.69846 5.79992C0.692218 5.18168 0.840654 4.65799 1.0856 4.24173C1.64861 3.28495 2.72148 2.89578 3.59782 3.23076C4.57404 3.60321 4.91577 4.89015 4.65481 5.50198C4.04071 6.93761 2.726 7.44008 1.22616 7.71501ZM3.95428 2.29645C4.77527 2.60968 5.27423 3.28317 5.52093 3.91076C5.75541 4.50723 5.8415 5.26862 5.57463 5.89431L5.57422 5.89527C4.75011 7.82187 2.9749 8.41112 1.40646 8.69862C1.19029 8.73817 1.12356 8.81976 1.0725 8.94879C0.995426 9.14354 0.964166 9.4894 1.05771 9.97021C1.14801 10.4344 1.33756 10.9322 1.58492 11.3664C1.83846 11.8116 2.11121 12.1178 2.3242 12.2667M3.95428 2.29645C3.14727 1.98817 2.30891 2.0657 1.59181 2.43324C2.21407 1.60964 3.29367 1.02535 4.41168 1.00125C5.73348 0.973985 6.91774 1.39434 8.043 2.15972C10.0321 3.51215 11.5656 5.27921 12.8642 7.30814C11.954 8.58019 10.9008 9.7283 9.69133 10.7419C8.71257 11.5617 7.7306 12.2344 6.607 12.6266C5.01224 13.1841 3.60327 13.1596 2.3242 12.2667' fill='%23F0532B'/%3E%3C/svg%3E");
            position: absolute;
            top: -2px;
            left: 0;
            z-index: 1;
            display: block;
        }
    }

    &__title {
        font-size: $font-size--2;
        line-height: 1.1em;
        font-weight: $font-weight-black;
        color: $color-text;
    }

    &__text {
        font-size: $font-size--text-medium;
        line-height: 1.4em;
        color: $color-text;
        margin-top: 10px;
    }

    &__more {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        line-height: 1.15em;
        font-style: italic;
        text-transform: uppercase;
        margin-top: 17.5px;
        transition: $duration;

        svg {
            width: 26px;
            height: 26px;
            fill: $color-white;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: $duration;
        }
    }

    /* Arrow */
    &-navigation {
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;

        &__item {
            width: 40px;
            height: 40px;
            background-color: $color-white;
            border: none;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0;
            transition: $duration;

            &--pause svg:last-child {
                display: none;
            }

            &--play svg {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    transform: rotate(180deg);
                }
            }

            svg {
                width: 26px;
                height: 26px;
                fill: $color-text;
            }

            &:hover,
            &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
}





//======================================================================================================
// Acces rapides
//======================================================================================================
.ar {
    background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--body repeat;
    padding: 45px 0 60px;

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &-item {
        width: 120px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &:hover,
        &:focus {
            .ar-item__icon {
                fill: $color-white;
                background-color: $color-second;
            }
        }

        &__icon {
            width: 120px;
            height: 120px;
            fill: $color-text;
            background-color: $color-main;
            border-radius: 30px;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--text-small;
            line-height: 1.2em;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-align: center;
            text-transform: uppercase;
            transition: $duration;
        }
    }
}





//======================================================================================================
// Actualites-Agenda
//======================================================================================================
.acag {
    position: relative;
    padding-bottom: 70px;
    margin-top: 50px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 30px;
        margin-top: 30px;
    }

    &-item {
        width: 570px;
        height: 360px;
        position: relative;
        display: flex;
        flex-direction: column;
        box-shadow: $shadow;
        overflow: hidden;

        &:hover,
        &:focus {
            .acag-item {
                &__content {
                    background-color: $color-main;
                }

                &__trick {
                    fill: $color-main;
                }
            }
        }

        &--post {
            &:hover,
            &:focus {
                .acag-item {
                    &__content {
                        height: 170px;
                        row-gap: 7.5px;
                    }

                    &__text {
                        height: auto;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        &__image {
            height: 280px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__type {
            height: 35px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-medium;
            color: $color-text;
            text-transform: uppercase;
            background-color: rgba(255, 255, 255, 0.85);
            border-radius: 0 0 20px 0;
            padding: 0 15px;
        }

        &__content {
            width: 100%;
            height: 90px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $color-bg--body;
            padding: 0 30px;
            transition: $duration;
        }

        &__trick {
            width: calc(100% + 5px);
            height: 30px;
            position: absolute;
            left: -2.5px;
            bottom: calc(100% - 1px);
            fill: $color-white;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.15em;
            font-weight: $font-weight-bold;
            color: $color-text;
        }

        &__text {
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: $duration;
        }
    }

    &-date {
        width: 160px;
        height: 160px;
        position: absolute;
        left: 20px;
        bottom: 40px;
        z-index: 1;
        background-color: $color-second;
        border-radius: $border-radius--round;

        &__container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 12.5px;
            margin-top: 35px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__day {
            font-size: $font-size--3;
            line-height: 1.1em;
            font-weight: $font-weight-black;
            color: $color-white;
            transition: $duration;
        }
    
        &__month {            
            font-size: $font-size--5;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            transition: $duration;
        }
    
        &__separator {
            width: 10px;
            height: 10px;
            fill: $color-white;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 25px;
        margin: 40px 0 0 auto;
    }

    &__heart {
        width: 380px;
        height: 370px;
        position: absolute;
        left: -75px;
        bottom: -132.5px;
        transform: rotate(30deg);
        fill: $color-second;
        opacity: .8;
        z-index: -1;
    }
}




//======================================================================================================
// Carte des communes
//======================================================================================================
.home {
    .map {
        height: 630px;
        position: relative;
        background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--neutral repeat;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: calc((100% - 1170px) / 2);
            background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/carte_situation.png') left 500px center no-repeat;
        }

        &__container {
            position: relative;
            z-index: 1;
            display: flex;
        }

        &__content {
            width: 430px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 95px;
        }

        &__text {
            max-width: 345px;
            font-size: $font-size--text-medium;
            line-height: 1.4em;
            font-weight: $font-weight-light;
            color: $color-text;
            margin-top: 20px;
        }

        &-dropdown {
            position: relative;
            display: none;
            margin-top: 25px;

            &:hover,
            &:focus {
                cursor: pointer;

                .map-dropdown__icon {
                    fill: $color-white;
                    background-color: $color-second;
                }
            }

            &__select {
                width: 300px;
                height: 60px;
                font-size: $font-size--text-small;
                border: 1px solid $color-dark;
                border-radius: 2px;
                padding: 0 10px;
                appearance: none;
                transition: $duration;

                &:focus {
                    border-color: $color-second;
                }
            }

            &__icon {
                width: 26px;
                height: 26px;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                fill: $color-text;
                background-color: $color-main;
                border-radius: $border-radius--round;
                pointer-events: none;
                transition: $duration;
            }
        }

        &-numbers {
            display: flex;
            column-gap: 50px;
            padding-left: 20px;
            margin-top: 60px;

            &__item {
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &::before {
                    content: '';
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    left: -20px;
                    z-index: -1;
                    background-color: $color-white;
                    border-radius: $border-radius--round;
                }
            }

            &__title {
                font-family: $font-family--heading;
                font-size: 45px;
                line-height: 1em;
                color: $color-second;
            }

            &__text {
                font-size: $font-size--text-medium;
                line-height: 1.2em;
                color: $color-text;
            }
        }

        &__button {
            margin-top: 65px;
        }

        &__item {
            width: 550px;
            height: 530px;
            position: relative;
            margin: 70px 0 0 -100px;

            svg {
                width: 100%;
                height: 100%;

                a  {
                    position: relative;

                    &.ville_hover,
                    &:focus {
                        path {
                            fill: $color-second;
                        }
                    } 

                    path {
                        fill: $color-white;
                        stroke: $color-second;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        transition: $duration;
                    }
                }
            }
        }

        &__tooltip {
            min-width: max-content;
            height: 30px;
            position: absolute;
            z-index: 2;
            display: none;
            align-items: center;
            font-size: $font-size--6;
            font-weight: $font-weight-medium;
            color: $color-white;
            background-color: $color-dark;
            padding: 0 10px;

            &::after {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                top: 100%;
                left: 15px;
                border-top: 10px solid $color-dark;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
            }

            &.active {
                display: flex;
            }
        }
    }
}





//======================================================================================================
// Social wall
//======================================================================================================
.sowl {
    position: relative;
    padding-bottom: 150px;
    margin-top: 95px;

    &__container {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-socials {
        display: flex;
        align-items: center;
        column-gap: 20px;

        &__title {
            display: flex;
            align-items: center;
            column-gap: 7.5px;
            font-size: $font-size--text-medium;
            line-height: 1.4em;
            font-weight: $font-weight-medium;
            font-style: italic;
            color: $color-text;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-text;
            }
        }

        &__items {
            display: flex;
            column-gap: 15px;
        }

        &__item {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
                transition: $duration;
            }
        }
    }

    &__heart {
        width: 380px;
        height: 370px;
        position: absolute;
        right: -180px;
        bottom: -40px;
        transform: rotate(-60deg);
        fill: $color-second;
        opacity: .8;
        z-index: -1;
    }


    #ff-stream-1 {
        width: 100%;
        min-height: auto !important;
        background-color: transparent;
        padding: 0;
        margin: 0;
    
        .ff-stream-wrapper {
            width: 100%;
            height: 310px !important;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
        }
    
        .ff-item {
            width: 370px !important;
            height: 100%;
            position: relative !important;
            border: 1px solid #F4EDE580;
            border-radius: $border-radius;
            overflow: hidden;
            transform: none !important;
            visibility: inherit !important;

            &:hover,
            &:focus {
                .ff-img-holder::after {
                    opacity: 1;
                }

                .ff-content,
                .ff-item-bar {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            .picture-item__inner {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                color: $color-white;
                background-color: transparent;
                box-shadow: none;
            }

            .ff-item-cont {
                height: 100%;
            }

            .ff-img-holder {
                height: 100%;
                position: relative;

                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    background: rgba(29, 29, 27, 0.9);
                    margin: 0;
                    opacity: 0;
                    transition: $duration;
                }

    
                img {
                    width: 100%;
                    height: 100%;
                    min-height: 100% !important;
                    object-fit: cover;
                }
            }

            .ff-content {
                width: 100%;
                height: auto;
                max-height: 180px;
                position: absolute;
                top: 40px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                font-size: $font-size--text;
                font-weight: $font-weight-normal;
                color: $color-white;
                padding: 0 30px;
                margin: 0;
                opacity: 0;
                pointer-events: none;
                transition: $duration;

                a {
                    color: $color-main;
                }
            }

            .ff-item-meta {
                display: none;
            }

            .ff-item-bar {
                width: 100%;
                height: 70px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                padding: 0 30px;
                border-top: none;
                opacity: 0;
                pointer-events: none;
                transition: $duration;

                a {
                    color: $color-white !important;
                }

                .ff-share-wrapper {
                    top: 0;
                    margin: 0 0 0 auto;

                    .ff-icon-share:before {
                        color: $color-white;
                    }

                    .ff-share-popup {
                        right: 0;
                        background-color: $color-main;
                        border-color: $color-main;

                        &::after {
                            background-color: $color-main;
                        }

                        a {
                            color: $color-text !important;
                        }
                    }
                }
            }
        }
    
        .ff-loadmore-wrapper,
        .shuffle__sizer {
            display: none;
        }
    }
}





//======================================================================================================
// Newsletter
//======================================================================================================
.newl {
    position: relative;
    z-index: 1;
    margin-top: -70px;

    &__container{
        height: 120px;
        display: flex;
        align-items: center;
        column-gap: 32.5px;
        background-color: $color-bg--body;
        border-radius: $border-radius;
        box-shadow: $shadow;
    }

    &__icon {
        width: 167px;
        height: 62px;
        fill: $color-main;
    }

    &__title {
        font-size: 35px;
        color: $color-text;

        &::before {
            content: url("data:image/svg+xml,%3Csvg width='14' height='14' aria-hidden='true' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.22616 7.71501C-1.03448 8.12868 0.244823 12.0339 1.75173 13.0866C3.37464 14.2197 5.14468 14.1971 6.9366 13.5708C8.22449 13.1212 9.31429 12.3622 10.3335 11.5084C11.7128 10.3525 12.8981 9.03183 13.9061 7.56379C14.0056 7.41919 14.0418 7.30487 13.9359 7.1339C12.5415 4.87803 10.8583 2.86466 8.60541 1.33286C7.34297 0.474174 5.95844 -0.0309648 4.39063 0.00147309C2.61008 0.0396425 0.865261 1.18624 0.264326 2.78293C0.0953548 3.23182 0.0330486 3.65903 0.0581225 4.06794C0.0958284 4.68285 0.331135 5.25637 0.69846 5.79992C0.692218 5.18168 0.840654 4.65799 1.0856 4.24173C1.64861 3.28495 2.72148 2.89578 3.59782 3.23076C4.57404 3.60321 4.91577 4.89015 4.65481 5.50198C4.04071 6.93761 2.726 7.44008 1.22616 7.71501ZM3.95428 2.29645C4.77527 2.60968 5.27423 3.28317 5.52093 3.91076C5.75541 4.50723 5.8415 5.26862 5.57463 5.89431L5.57422 5.89527C4.75011 7.82187 2.9749 8.41112 1.40646 8.69862C1.19029 8.73817 1.12356 8.81976 1.0725 8.94879C0.995426 9.14354 0.964166 9.4894 1.05771 9.97021C1.14801 10.4344 1.33756 10.9322 1.58492 11.3664C1.83846 11.8116 2.11121 12.1178 2.3242 12.2667M3.95428 2.29645C3.14727 1.98817 2.30891 2.0657 1.59181 2.43324C2.21407 1.60964 3.29367 1.02535 4.41168 1.00125C5.73348 0.973985 6.91774 1.39434 8.043 2.15972C10.0321 3.51215 11.5656 5.27921 12.8642 7.30814C11.954 8.58019 10.9008 9.7283 9.69133 10.7419C8.71257 11.5617 7.7306 12.2344 6.607 12.6266C5.01224 13.1841 3.60327 13.1596 2.3242 12.2667' fill='%231D1D1B'/%3E%3C/svg%3E");
            top: -2.5px;
        }
    }

    &__text {
        margin-top: 7.5px;
    }

    &__button {
        margin: 0 30px 0 auto;
    }
}




//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &__item {
                height: 460px !important;
            }

            &-content {
                bottom: 95px;

                &__container {
                    max-width: 495px;
                    padding: 40px 35px 32.5px;
                }
            }

            &__title {
                font-size: 32px;
                line-height: 1.2em;
            }

            &__text {
                font-size: $font-size--text;
            }

            /* Arrow */
            &-navigation {
                bottom: 30px;
            }
        }





        //======================================================================================================
        // Acces rapides
        //======================================================================================================
        .ar {
            padding: 45px 0 55px;

            &-item {
                width: 100px;
                row-gap: 10px;

                &--mediatheque {
                    width: 110px;
                    align-items: center;
                }

                &__icon {
                    width: 100px;
                    height: 100px;
                }

                &__title {
                    font-size: 12px;
                    line-height: 1.15em;
                }
            }
        }





        //======================================================================================================
        // Actualites-Agenda
        //======================================================================================================
        .acag {
            margin-top: 60px;
        
            &__items {
                row-gap: 20px;
                margin-top: 27.5px;
            }
        
            &-item {
                width: 460px;
                height: 305px;
        
                &__image {
                    height: 225px;
                }
        
                &__content {
                    height: 80px;
                    padding: 0 20px;
                }

                &__trick {
                    height: 28px;
                    bottom: 100%;
                }
            }
        
            &-date {
                width: 130px;
                height: 130px;
                bottom: 45px;
        
                &__container {
                    margin-top: 30px;
                }
        
                &__day {
                    font-size: 25px;
                }
            
                &__month {            
                    font-size: $font-size--text;
                }
            }
        
            &__buttons {
                column-gap: 20px;
            }
        
            &__heart {
                left: -150px;
                bottom: -155px;
            }
        }





        //======================================================================================================
        // Carte des communes
        //======================================================================================================
        .home {
            .map {        
                &::after {
                    left: calc((100% - 960px) / 2);
                    background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/carte_situation.png') left 345px center no-repeat;
                }

                &__content {
                    width: 380px;
                }
            
                &__text {
                    max-width: 310px;
                    font-size: $font-size--6;
                    line-height: 1.6em;
                }
            
                &-numbers {
                    column-gap: 40px;
                    padding-left: 17.5px;
            
                    &__item::before {
                        top: -5px;
                    }
            
                    &__title {
                        font-size: 35px;
                        line-height: 1.25em;
                    }
            
                    &__text {
                        font-size: $font-size--6;
                    }
                }
            
                &__button {
                    margin-top: 130px;
                }
            
                &__item {
                    width: 500px;
                    height: 480px;
                    margin: 100px 0 0 -127.5px;
                }
            }
        }





        //======================================================================================================
        // Social wall
        //======================================================================================================
        .sowl {
            &__heart {
                right: -265px;
                bottom: -80px;
            }


            #ff-stream-1 {            
                .ff-stream-wrapper {
                    height: 250px !important;
                }
            
                .ff-item {
                    width: 300px !important;

                    .ff-content {
                        max-height: 140px;
                    }

                    .ff-item-bar {
                        height: 50px;
                    }
                }
            }
        }





        //======================================================================================================
        // Newsletter
        //======================================================================================================
        .newl {
            &__container{
                column-gap: 20px;
            }

            &__icon {
                width: 130px;
                height: 48px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        //======================================================================================================
        // Global
        //======================================================================================================
        body {
            margin-top: 0;
        }

        .home {
            [data-aos] {
                visibility: visible;
                transform: none;
            }
        }





        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
            
            &__item {
                height: 370px !important;
            }

            &-content {
                bottom: 30px;

                &__container {
                    max-width: 430px;
                    padding: 35px 30px 22.5px;
                }
            }

            &__alu {
                margin-bottom: 2.5px;
            }

            &__title {
                font-size: 28px;
                line-height: 1.1em;
            }

            &__text {
                margin-top: 5px;
            }

            &__more {
                margin-top: 15px;
            }
        }





        //======================================================================================================
        // Acces rapides
        //======================================================================================================
        .ar {
            &__container {
                flex-wrap: wrap;
                column-gap: 70px;
                row-gap: 30px;
            }
        }





        //======================================================================================================
        // Actualites-Agenda
        //======================================================================================================
        .acag {        
            &__items {
                margin-top: 30px;
            }
        
            &-item {
                width: 300px;
                height: 260px;
        
                &--post {
                    &:hover,
                    &:focus {
                        .acag-item__content {
                            height: 190px;  
                        }
                    }
                }
        
                &__image {
                    height: 150px;
                }
        
                &__content {
                    height: 110px;
                }

                &__trick {
                    height: 18px;
                }
            }
        
            &-date {
                width: 110px;
                height: 110px;
                left: 15px;
                bottom: 70px;
        
                &__container {
                    column-gap: 7.5px;
                    margin-top: 25px;
                }

                &__item {
                    row-gap: 2.5px;
                }

                &__separator {
                    width: 5px;
                    height: 5px;
                }
        
                &__day {
                    font-size: $font-size--text;
                }
            
                &__month {            
                    font-size: $font-size--text-small;
                }
            }
        
            &__buttons {
                margin-top: 35px;
            }
        
            &__heart {
                width: 350px;
                height: 340px;
                left: -215px;
                bottom: -145px;
            }
        }





        //======================================================================================================
        // Carte des communes
        //======================================================================================================
        .home {
            .map {    
                height: 560px;

                &::after {
                    left: calc((100% - 640px) / 2);
                    background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/carte_situation_responsive.png') left 100px center no-repeat;
                }

                &__content {
                    margin-top: 135px;
                }

                &-dropdown {
                    display: flex;
                }
            
                &__text,
                &__item {
                    display: none;
                }
            
                &-numbers {
                    margin-top: 35px;
            
                    &__title {
                        font-size: $font-size--3;
                    }
            
                    &__text {
                        font-size: $font-size--text;
                    }
                }
            
                &__button {
                    margin-top: 35px;
                }
            }
        }





        //======================================================================================================
        // Social wall
        //======================================================================================================
        .sowl {
            padding-bottom: 155px;
            margin-top: 75px;

            &-socials__title {
                display: none;
            }

            &__heart {
                width: 350px;
                height: 340px;
                right: -230px;
                bottom: -55px;
            }

            #ff-stream-1 {                        
                .ff-item:nth-child(3) {
                    display: none !important;
                }
            }
        }





        //======================================================================================================
        // Newsletter
        //======================================================================================================
        .newl {
            margin-top: -95px;

            &__container {
                height: 190px;
            }

            &__icon {
                width: 145px;
                height: 55px;
                margin-bottom: 40px;
            }

            &__content {
                max-width: 210px;
            }

            &__button {
                margin: 40px 50px 0 auto;
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--body repeat;

            &__item {
                height: auto !important;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__media {
                height: 250px;
            }

            &-content {
                position: relative;
                left: unset;
                bottom: unset;
                transform: unset;
                margin-top: -30px;

                &__container {
                    width: 300px;
                    max-width: unset;
                    padding: 25px;
                    background-color: $color-white;
                    box-shadow: $shadow;
                }
            }

            &__more {
                margin-top: 10px;
            }

            &-navigation {
                top: 170px;
                bottom: unset;

                &__item {
                    width: 35px;
                    height: 35px;

                    &--mode {
                        display: none;
                    }
                }
            }
        }





        //======================================================================================================
        // Acces rapides
        //======================================================================================================
        .ar {
            padding-top: 40px;

            &__container {
                justify-content: center;
                column-gap: 40px;
                row-gap: 12.5px;
            }
        }





        //======================================================================================================
        // Actualites-Agenda
        //======================================================================================================
        .acag {  
            margin-top: 57.5px;

            &__items {
                margin-top: 35px;
            }
        
            &__buttons {
                flex-direction: column-reverse;
                align-items: flex-start;
                row-gap: 20px;
                margin-left: 0;
            }
        
            &__heart {
                width: 205px;
                height: 200px;
                left: -25px;
                bottom: -65px;
            }
        }





        //======================================================================================================
        // Carte des communes
        //======================================================================================================
        .home {
            .map {    
                height: 485px;

                &::after {
                    display: none;
                }

                &__content {
                    width: 100%;
                    margin-top: 67.5px;
                }

                &-dropdown {
                    margin-top: 30px;
                }
            
                &-numbers {
                    width: 100%;
                    justify-content: space-between;
                    column-gap: unset;
                    margin-top: 45px;
                }
            }
        }





        //======================================================================================================
        // Social wall
        //======================================================================================================
        .sowl {
            padding-bottom: 230px;
            margin-top: 70px;

            &__container {
                row-gap: 35px;
            }

            &__header {
                flex-direction: column-reverse;
                align-items: flex-start;
                row-gap: 22.5px;
            }

            &__title {
                padding-left: 20px !important;
            }

            &-socials {
                margin: 0 0 0 auto;

                &__item {
                    width: 40px;
                    height: 40px;
                }
            }

            &__heart {
                width: 205px;
                height: 200px;
                right: -30px;
                bottom: -50px;
            }

            #ff-stream-1 {
                .ff-item {
                    height: 250px;
                }

                .ff-stream-wrapper {    
                    height: auto !important;                    
                    flex-direction: column;
                    row-gap: 20px;
                }
            }
        }





        //======================================================================================================
        // Newsletter
        //======================================================================================================
        .newl {
            margin-top: -170px;

            &__container {
                height: 340px;
                flex-direction: column;
                align-items: flex-start;
                padding: 0 45px;
            }

            &__icon {
                margin: 37.5px 0 0 -45px;
            }

            &__content {
                max-width: unset;
                margin-top: 25px;
            }

            &__button {
                margin: 15px 0 0;
            }
        }
    }