.form-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: calc(100% - 200px);

    label {
        display: none;
    }

    .dropdown-submit {
        position: relative;
        padding: 0;
        margin: 0 0 0 -3px;
        border-radius: 0 $border-radius $border-radius 0;
        background-color: $color-dark;
        color: $color-white;
        width: 65px;
        height: 70px;
        border-width: 1px;

        &:hover, &:focus {
            background-color: $color-light;
            border-color: $color-gray;
            color: $color-text;
        }
    }

    .select2, .list-select2 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $color-gray;
        box-sizing: border-box;
        border-radius: $border-radius;
        background-color: $color-white;
        height: 70px;
        flex: 1;

        &-container--open {
            border-color: $color-bg--transparent;
            box-shadow: $shadow;
        }

        &.select2-container--open {
            &:after {
                transform: rotate(180deg);
            }
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;    
            background: $color-light url(/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/sprite-images/base-icons/ic-dropdown-chevron.svg) no-repeat 50%;
            border-radius: $border-radius--round; 
            transform: rotate(0);
            transition: .45s;
        }

        .selection {
            height: 100%;
            width: 100%;
        }

        &-selection {
            height: 100%!important;
            border: none!important;
            display: flex!important;
            align-items: center;
            z-index: 1;
            position: relative;
            background-color: $color-bg--transparent!important;

            &__rendered {
                width: 100%;
                padding: 5px 70px 5px 20px!important;
            }

            &__placeholder {
                color: $color-text;
            }

            &__arrow {
                display: none;
            }
        }
    }
}


.select2 {
    &-dropdown {
        background-color: $color-light!important;
        box-shadow: $shadow;
    }

    &-search {
        &--dropdown {
            position: relative;
            padding: 0!important;
            margin: 25px 20px 0;
        
            &:after {
                content: '';
                position: absolute;
                right: 5px;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background: $color-white url(/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/sprite-images/base-icons/ic-misc-search.svg) no-repeat 50%;
            }

            .select2-search__field {
                height: 50px;
                background-color: $color-white;
                padding: 0 50px 0 15px!important;

                &:focus {
                    border-color: $color-dark;
                } 
            }
        }
    }

    &-results {
        background-color: $color-light;
        padding: 20px 20px 10px;

        &__option {
            background-color: $color-white;
            border-radius: $border-radius;
            min-height: 40px;
            margin-bottom: 10px;
            padding: 5px 15px!important;
            display: flex;
            align-items: center;
            transition: .45s ease;
        
            &:hover {
                background-color: $color-dark;
                color: $color-white;
            }

            &--highlighted {
                background-color: $color-dark!important;
                color: $color-white!important;
            }
        }
    }

    &-container {
        &--open {
            .select2-dropdown--above {
                border: none;
            }
        }

        .select2-search--dropdown {
            .select2-search__field {
                &:focus {
                    border-color: $color-dark;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .form-dropdown {
        width: calc(100% - 100px);
    }
}


// 640
@media screen and (max-width: $small) {
    .form-dropdown {
        width: 100%;
    }
}
