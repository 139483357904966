//=================================================================================================
// Font face rules
//=================================================================================================

// Usage : @include font-face("My Font", my-font);
@mixin font-face($name, $file) {
    @font-face {
        font-family: "#{$name}";
        src: url("../fonts/#{$file}.eot");
        src: url("../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
        url("../fonts/#{$file}.woff") format("woff"),
        url("../fonts/#{$file}.ttf") format("truetype"),
        url("../fonts/#{$file}.svg?#webfont") format("svg");
    }
}


//=================================================================================================
// Font size rules
//=================================================================================================

// Usage : "font-size : toRem(24);"
@function toRem($value) {
    $remValue: calc($value / 16) + rem;    
    @return $remValue;
}

// Usage : @include font-size(16);
@mixin font-size($size) {
    font-size: toRem($size);
}


//=================================================================================================
// Offset Positioning
//=================================================================================================
 
// Shorthand mixin for offset positioning
// Usage : @include position(absolute, $top: 1em, $left: 50%);
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

// Shorthand mixin for absolute positioning
// Usage : @include absolute($top: 1em, $left: 50%);
@mixin absolute($args...) {
    @include position(absolute, $args...);
}

// Shorthand mixin for relative positioning
// Usage : @include relative($top: 1em, $left: 50%);
@mixin relative($args...) {
    @include position(relative, $args...);
}

// Shorthand mixin for fixed positioning
// Usage : @include fixed($top: 1em, $left: 50%);
@mixin fixed($args...) {
    @include position(fixed, $args...);
}


//=================================================================================================
// Size
//=================================================================================================

//  Usage : @include size(32px);
//  Usage : @include size(100%, 10em);
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}


//=================================================================================================
// Flexbox
//=================================================================================================

//  Usage : @include flex($alignItems: center, $justifyContent: center);
//  Usage : @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
@mixin flex($direction: null, $wrap: null, $alignItems: null, $justifyContent: null) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    align-items: $alignItems;
    justify-content: $justifyContent;
}