.bloc-panoramic {
    position: relative;
    @include flex($alignItems: center);
    @include size(100%, 530px);
    margin: 40px 0;

    &--content {
        &::after {
            content: "";
            @include absolute($top: 0, $left: 0);
            z-index: 0;
            @include size(100%);
            background: linear-gradient(270deg, rgba($color-black, 0.09) 30%, rgba($color-black, 0.45) 80%);
            pointer-events: none
        }
    }
    
    picture, img {
        @include size(100%);
        object-fit: cover;
        object-position: top;
    }

    &__container, &__content {
        @include absolute($top: 0, $bottom: 0, $left: 0, $right: 0);
    }

    &__content {
        z-index: 1;
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
        gap: 10px;
        max-width: 400px;
        filter: drop-shadow(0px 0px 30px $color-dark);
        color: $color-white;
    }

    &__title {
        margin: 0;
        padding: 0;
        border: 0;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--2;
        line-height: $line-height--heading;
        color: $color-white;

        &::before, &::after {
            display: none;
        }
    }

    &__desc {
        font-size: toRem(18);
    }

    &__button {
        margin: 10px 0 0 0;

        &:hover, &:focus {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-text;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .bloc-panoramic {
        height: 410px;

        &__content {
            max-width: 430px;
        }
    }  

}


// 960
@media screen and (max-width: $medium) {

    .bloc-panoramic {
        &__desc {
            font-size: $font-size--text;
        }
    }  

}


// 640
@media screen and (max-width: $small) {
   
    .bloc-panoramic--content::after {
        background: linear-gradient(270deg, rgba($color-black, .27) 30%, rgba($color-black, .45) 80%);
    }

}
