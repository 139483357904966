.cover {
    &__image {
        width: 100%;
        height: 400px;
        background: $color-bg--image;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__container {
        width: 100%;
        position: relative;
        padding-bottom: 35px;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background: url('/wp-content/themes/cc-sud-vendee-littoral/assets/src/images/motif.png') $color-bg--body repeat;
            transition: $duration;
        }
    }

    &__content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 60px 50px;
        z-index: 2;

        &::before {
            content: '';
            width: 100%;
            height: calc(100% + 50px);
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            background-color: $color-main;
            border-radius: 40px 2px;
            transition: $duration;
        }
    }

    &__title {
        margin: 0;
    }

    &__text {
        font-size: $font-size--text-medium;
        line-height: 1.4em;
        margin-top: 17.5px;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {        
            &__image {
                height: 360px;
            }
        
            &__content {     
                padding: 0 50px 40px;
                
                &::before {
                    height: calc(100% + 40px);
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {        
            &__image {
                height: 320px;
            }
        
            &__content {     
                padding: 0 40px 30px;   

                &::before {
                    height: calc(100% + 30px);
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .cover {       
            &__image {
                height: 280px;
            }
        
            &__content {   
                padding: 0 20px 20px;
            }

            &__text {
                font-size: $font-size--6;
            }
        }
    }