body.admin-bar .header {
    top: 32px;
}

.header {
    width: 100%;
    height: 175px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    background-color: $color-bg--body;
    transition: $duration;

    &--fixed {
        height: 90px;
        box-shadow: $shadow;

        .main-logo {
            width: 185px;
            height: 68px;
            margin-top: 7.5px;
        }

        .header__content {
            flex-direction: row-reverse;
            align-items: center;
            column-gap: 45px;
        }

        .tools {
            margin: 0;

            #accessconfig {
                display: none;
            }

            &__icon {
                width: 40px;   
                height: 40px;
            }

            &__text {
                display: none;
            }
        }

        .nav-menu {
            column-gap: 70px;
    
            &__item {
                &:nth-last-child(2) {
                    margin-right: -25px;
                }
    
                &:last-child {    
                    .nav-menu__link::after {
                        height: 100%;
                        bottom: 0;
                        border-radius: 0;
                    }
                }
            }
    
            &__link {
                height: 90px;
                justify-content: center;
            }
        }
    }

    &__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
}


.main-logo {
    width: 300px;
    height: 112px;
    margin-top: 25px;
    transition: $duration;

    svg {
        width: 100%;
        height: 100%;
        transition: $duration;
    }

    &__title {
        display: none;
    }
}



//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin: 27.5px 170px 0 0;

    &__item {
        display: flex;
        align-items: center;
        column-gap: 7.5px;
        background-color: unset;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;

        &:hover,
        &:focus {
            background-color: transparent;

            .tools__icon {
                fill: $color-text;
                background-color: $color-main;
            }
        }

        &--disabled {
            opacity: .2;
            pointer-events: none;
        }
    }

    &__icon {
        width: 26px;
        height: 26px;
        fill: $color-white;
        background-color: $color-second;
        border-radius: $border-radius--round;
        transition: $duration;
    }

    &__text {
        font-size: $font-size--text-small;
        line-height: 1.15em;
        font-weight: $font-weight-normal;
        font-style: normal;
        color: $color-text;
        text-transform: uppercase;
    }
}

#uci_link {
    display: none;
}



//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover;
        }
    }
}





//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {        
        .header--fixed {        
            .header__content {
                column-gap: 30px;
            }            
    
            .nav-menu {
                column-gap: 40px;
        
                &__item:nth-last-child(2) {
                    margin-right: -10px; 
                }
            }
        }
        
        
        .main-logo {
            width: 240px;
            height: 90px;
            margin-top: 30px;
        }
        
        
        
        //======================================================================================================
        // Tools
        //======================================================================================================
        .tools {
            margin: 25px 130px 0 0;
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        body.admin-bar .header {
            top: unset;
        }

        .header {
            height: 135px;
            position: relative;
            top: unset;
            left: unset;

            &__content {
                flex-direction: row-reverse;
                column-gap: 20px;
                padding-bottom: 30px;
            }
        }

        .main-logo {
            margin-top: 20px;
        }

        .tools {
            column-gap: 15px;
            margin: 0;

            &__icon {
                width: 40px;
                height: 40px;
            }

            &__text {
                display: none;
            }
        }


        .privacy {
            &__container {
                flex-wrap: wrap;
            }

            &__button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .header {
            height: 100px;

            &__content {
                column-gap: 10px;
                padding-bottom: 25px;
            }
        }

        .main-logo {
            width: 150px;
            height: 56px;
        }

        .tools {
            column-gap: 10px;

            &__icon {
                width: 35px;
                height: 35px;
            }
        }
    }